import React from 'react';

// import TitleLinks from "../TitleLinks";

export default function Artwave2() {
    return (
        <div>
            <p>[Artwave 2.0 summary]</p>
        </div>
    )
}
